<template>
    <activix-modal size="lg" :opened="opened" @close="close">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('imports.fieldInfoModal.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="alert alert-info | mb-2 text-center">
                <p>{{ $t('imports.fieldInfoModal.description') }}</p>
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th class="whitespace-nowrap">
                            {{ $t('imports.fieldInfoModal.fieldName') }}
                        </th>
                        <th>{{ $t('imports.fieldInfoModal.fieldInfo') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="section in sections">
                        <tr :key="field" v-for="(value, field) in section">
                            <td class="whitespace-nowrap">
                                <strong>{{ getFieldName(field) }}</strong>
                            </td>
                            <td>
                                {{ value }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.close') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { upperFirst } from 'lodash-es';

    const importRules = {
        email: 'email@example.com',
        phone: '555-555-5555',
        date: '2016-06-24',
        dateTime: '2016-06-24 15:18',
        year: '2017',
        money: '12345678.90',
        boolean: 'true/yes/oui | false/no/non',
        advisorName: "Advisor's name | Nom du conseiller",
        integer: '1234567890',
    };

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                sections: {
                    personal: {
                        secondContact: 'John Smith',
                        emailHome: importRules.email,
                        emailWork: importRules.email,
                        unsubscribe: importRules.boolean,
                        phoneHome: importRules.phone,
                        phoneWork: importRules.phone,
                        phoneCell: importRules.phone,
                        locale: 'anglais/english/en | français/french/fr',
                        postalCode: 'H1K 1K1',
                        province: 'AB, BC, MB, NB, NL, NT, NS, NU, ON, QC, PE, SK, YT, AL, AK, AS, AZ, AR, CA, CO, CT, DE, DC, FM, FL, GA, GU, HI, ID, IL, IN, IA, KS, KY, LA, ME, MH, MD, MA, MI, MN, MS, MO, MT, NE, NV, NH, NJ, NM, NY, NC, ND, MP, OH, OK, OR, PW, PA, PR, RI, SC, SD, TN, TX, UT, VT, VI, VA, WA, WV, WI, WY',
                        country: 'CA, US',
                        birthDate: importRules.date,
                        civility:
                            'm/m./mr/mr./monsieur/mister | mme/ms/mrs/madame/mistress/madam | mlle/miss/mademoiselle',
                        sex: 'women/woman/femme/female/mlle/mme/w/f | men/man/homme/male/mr/m',
                    },
                    general: {
                        casl_consent: 'raison/reason',
                        divisionId: 'new/neuf | used/occasion/usager',
                        userId: importRules.advisorName,
                        bdcUserId: importRules.advisorName,
                        commercialId: importRules.advisorName,
                        createdAt: importRules.dateTime,
                        updatedAt: importRules.dateTime,
                        callDate: importRules.dateTime,
                        status: 'duplique/duplicate | perdu/lost | invalide/invalid',
                        result: 'tentative/attempted | rejoint/reached',
                        lostReason: 'dealer | client',
                        sid:
                            "***DOIT VENIR D'UN EXPORT DU CRM ACTIVIX SEULEMENT*** - LD246XpJ4BOXq8Pw8HtNrAnJzwAYhS5ANz",
                    },
                    performance: {
                        appointment: importRules.boolean,
                        appointmentDate: importRules.dateTime,
                        presented: importRules.boolean,
                        presentedDate: importRules.dateTime,
                        sale: importRules.boolean,
                        saleDate: importRules.date,
                        beBack: importRules.boolean,
                        beBackDate: importRules.date,
                        roadTest: importRules.boolean,
                        roadTestDate: importRules.date,
                        takeOver: importRules.boolean,
                        takeOverDate: importRules.date,
                        takeOverDirector: importRules.advisorName,
                    },
                    process: {
                        delivered: importRules.boolean,
                        deliveredDate: importRules.dateTime,
                        deliveryDate: importRules.dateTime,
                        refinanced: importRules.boolean,
                        refinancedDate: importRules.date,
                        csi: importRules.boolean,
                        csiDate: importRules.dateTime,
                        availableDate: importRules.dateTime,
                        deliverableDate: importRules.dateTime,
                        approved: importRules.boolean,
                        prepared: importRules.boolean,
                        inspected: importRules.boolean,
                        institution:
                            'National | TD | TD 2e chance | Desjardins | Scotia 2e chance | Cash deal | Autonum | Crélogix | Autonum 2e chance | Manufacturier | Royale | CIBC | BMO | BLC | Accès crédit | Financement manufacturier | Financement manufacturier | Location manufacturier | Alphera | Location maison | Location commercial',
                    },
                    vehicle: {
                        year: importRules.year,
                        recordedDate: importRules.dateTime,
                        modality: 'finance/financing/financement | cash/comptant | leasing/lease/location/renting',
                        rate: importRules.integer,
                        accessories: importRules.integer,
                        preparation: importRules.integer,
                        documentation: importRules.integer,
                        term: importRules.integer,
                        payment: importRules.integer,
                        profit: importRules.integer,
                        category:
                            'motorcycle/motocyclette | bike/vélo | snowmobile/motoneige/ski-doo/skidoo | watercraft/motomarine/sea-doo/seadoo | boat/bateau | atv/vtt | van/camionette | truck/camion | suv/vus | car/automotive/automobile | motorise/motorized/rv/vr | utility/utilitaire | trailer/caravan/roulotte',
                        categoryRv:
                            'class_a_diesel | motorise_a/motorized_a | motorise_b/motorized_b | motorise_c/motorized_c | travel_trailer | fifth_wheel | trailer_park | tent_trailer',
                        fuel: 'essence/gasoline/gas/gaz/petrol | diesel',
                        lengthMin: importRules.integer,
                        lengthMax: importRules.integer,
                        sleeping: importRules.integer,
                        budgetMin: importRules.integer,
                        budgetMax: importRules.integer,
                        endContractDate: importRules.date,
                    },
                },
            };
        },

        methods: {
            getFieldName(field) {
                const upperField = upperFirst(field);
                const keyPaths = [
                    `imports.fields.${field}`,
                    `imports.fields.wanted${upperField}`,
                    `imports.fields.exchange${upperField}`,
                ];

                for (const keyPath of keyPaths) {
                    if (this.$te(keyPath)) {
                        return this.$t(keyPath);
                    }
                }

                return field;
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
