<template>
    <div class="flex flex-col">
        <warning
            icon="regular/cloud-upload"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="shouldHideImportList"
        />
        <template v-else>
            <div class="flex-1 | xl:flex xl:space-x-6">
                <div class="w-full | xl:w-1/3 2xl:w-1/4">
                    <div class="box" :class="{ loading: !accountId }">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="flex items-center">
                                    {{ $t('imports.upload.selectFile') }}
                                    <icon
                                        name="regular/information-circle"
                                        class="link-grey-light text-sm ml-1"
                                        @click="openInfoModal"
                                    />
                                </label>

                                <activix-upload accepts=".csv" ref="file" v-model="file" />
                            </div>

                            <div class="form-group">
                                <label>{{ $t('imports.upload.delimiter') }}</label>
                                <activix-multiselect
                                    :selected="delimiter"
                                    :options="delimiters"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :multiple="false"
                                    @update="setDelimiter"
                                />
                            </div>

                            <div class="text-center">
                                <activix-button
                                    type="primary"
                                    :disabled="!delimiter || !file || !accountId"
                                    :loading="creating"
                                    @click="submit"
                                >
                                    {{ $t('imports.upload.button') }}
                                </activix-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex w-full | xl:w-2/3 2xl:w-3/4">
                    <div class="box | flex flex-col" :class="{ loading: loading || !accountId }">
                        <div class="box-header | h-auto p-6 flex justify-between items-center">
                            <div class="flex items-baseline">
                                <h4 class="box-title">
                                    {{ $t('imports.table.title') }}
                                </h4>
                                <activix-reload :loading="loading" @click="fetch" />
                            </div>
                            <div>
                                <input
                                    class="form-control"
                                    type="text"
                                    :placeholder="$t('search.search')"
                                    v-model="tableState.searchToken"
                                />
                            </div>
                        </div>
                        <div class="box-body | flex-1 flex flex-col p-0 h-0">
                            <template v-if="tableData.length > 0">
                                <el-table
                                    class="w-full border-t-2 border-b overflow-auto"
                                    :border="true"
                                    :data="paginatedFiles"
                                    :default-sort="{ prop: 'created_at', order: 'descending' }"
                                    :stripe="true"
                                    @sort-change="updateTableSorting"
                                >
                                    <el-table-column
                                        label="ID"
                                        prop="import_id"
                                        header-align="center"
                                        :sortable="true"
                                        v-if="authUser.isAdmin()"
                                    />
                                    <el-table-column
                                        header-align="center"
                                        min-width="160"
                                        prop="original_file"
                                        :label="$t('imports.table.headings.originalFile')"
                                        :sortable="true"
                                    >
                                        <template slot-scope="{ row }">
                                            <router-link
                                                :to="{ name: 'imports.match', params: { id: row.import_id } }"
                                                v-if="
                                                    !as_locale(row.started_at, 'started_at').isValid() &&
                                                        !as_locale(row.finished_at, 'finished_at').isValid()
                                                "
                                            >
                                                {{ row.original_file }}
                                            </router-link>
                                            <span class="whitespace-pre-wrap" v-else>{{ row.original_file }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        align="center"
                                        min-width="120"
                                        prop="account"
                                        :label="$t('imports.table.headings.account')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        min-width="120"
                                        prop="lead_type"
                                        :label="$t('imports.table.headings.leadType')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        min-width="110"
                                        prop="imported_by"
                                        :label="$t('imports.table.headings.importedBy')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="row_count"
                                        width="90"
                                        :label="$t('imports.table.headings.qtyInFile')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="processed"
                                        width="85"
                                        :label="$t('imports.table.headings.qtyProcessed')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="created"
                                        width="65"
                                        :label="$t('imports.table.headings.created')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="merged"
                                        width="85"
                                        :label="$t('imports.table.headings.qtyMerged')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="created_at"
                                        width="110"
                                        :formatter="handleTableDateTimeFormat"
                                        :label="$t('imports.table.headings.createdAt')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="started_at"
                                        width="110"
                                        :formatter="handleTableDateTimeFormat"
                                        :label="$t('imports.table.headings.startedAt')"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="progress"
                                        width="100"
                                        :label="$t('imports.table.headings.progress')"
                                        :sortable="true"
                                    />
                                    <el-table-column
                                        align="center"
                                        prop="finished_at"
                                        width="110"
                                        :formatter="handleTableDateTimeFormat"
                                        :label="$t('imports.table.headings.finishedAt')"
                                    />
                                    <el-table-column
                                        align="center"
                                        column-key="validate"
                                        header-align="center"
                                        prop="validated"
                                        width="50"
                                    >
                                        <template slot="header" slot-scope="{}">
                                            <icon name="regular/check-double" class="check-header" />
                                        </template>
                                        <template slot-scope="{ row }">
                                            <div class="flex items-center justify-center">
                                                <activix-checkbox
                                                    :disabled="!as_locale(row.finished_at, 'finished_at').isValid()"
                                                    :value="row.validated == true"
                                                    @click.native.prevent="onValidate(row)"
                                                />
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" column-key="delete" width="50">
                                        <template slot="header" slot-scope="{}">
                                            <icon :name="$icons.trash" />
                                        </template>
                                        <template slot-scope="{ row }">
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon
                                                    :name="$icons.trash"
                                                    class="link-grey | hover:text-red-500"
                                                    @click="onDelete(row)"
                                                    v-if="authUser.isAdmin() || authUser.isSameUser(row.created_by)"
                                                />
                                            </activix-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                    class="p-6"
                                    :layout="paginationLayout"
                                    :background="true"
                                    :current-page.sync="tableState.currentPage"
                                    :page-size.sync="tableState.perPage"
                                    :page-sizes="tableState.pageOptions"
                                    :pager-count="5"
                                    :total="filteredSortedFiles.length"
                                    @size-change="updateTablePerPage"
                                    v-if="tableData.length > 0"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <imported-fields :opened.sync="importedFieldsModalOpened" />

        <activix-confirm-modal
            type="warning"
            :content="$t('imports.validateModal.title')"
            :opened.sync="validateModal.opened"
            @approve="triggerValidate"
            @closed="onValidateModalClose"
        />

        <activix-confirm-modal
            :type="deleteModal.type"
            :content="deleteModal.content"
            :opened.sync="deleteModal.opened"
            @approve="triggerDelete"
            @closed="deleteModal.importId = null"
        />
    </div>
</template>

<script>
    import { get, orderBy } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    // Pinia
    import { useImportStore } from '../../store/modules/imports/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    // Mixins
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';

    // Components
    import ImportedFields from '../../components/modals/ImportedFields.vue';
    import Warning from '../../components/Warning.vue';
    import LeadType from '../../entities/LeadType.js';

    export default {
        name: 'ImportsList',

        components: {
            ImportedFields,
            Warning,
        },

        mixins: [TrackView, DataTableMixin],

        data() {
            return {
                tableData: [],
                loaded: false,
                loading: false,
                creating: false,
                delimiters: [',', ';', '|'],
                delimiter: ',',
                emptyChar: '-',
                file: null,
                importedFieldsModalOpened: false,
                validateModal: {
                    opened: false,
                    importId: null,
                    validated: false,
                },
                deleteModal: {
                    opened: false,
                    importId: null,
                    type: 'warning',
                    content: this.$t('imports.deleteModal'),
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextChildAccount: 'contextChildAccount',
                contextAccount: 'account',
                contextGroup: 'group',
            }),
            ...mapState(useImportStore, ['imports']),

            shouldHideImportList() {
                return !get(this.contextChildAccount, 'id') || !!get(this.contextGroup, 'id');
            },

            accountId() {
                if (!this.contextAccount) {
                    return null;
                }

                return this.contextAccount.id;
            },

            filteredSortedFiles() {
                const searchedData = this.searchData(this.tableState.searchToken, this.tableData);

                return orderBy(searchedData, [this.tableState.sorting.column], [this.tableState.sorting.order]);
            },

            paginatedFiles() {
                return this.filteredSortedFiles.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        watch: {
            imports: {
                handler() {
                    this.initData();
                },
                deep: true,
            },

            accountId() {
                if (!this.loaded) {
                    return;
                }

                this.fetch();
            },
        },

        methods: {
            ...mapActions(useImportStore, ['fetchImports', 'updateImport', 'deleteImport', 'createImport']),

            // Not a computed since we don't always want to update it on creation
            initData() {
                if (this.creating) {
                    return;
                }

                this.tableData = this.imports.map(importData => {
                    return {
                        import_id: importData.id,
                        account: importData.account.name,
                        original_file: importData.original_file || '-',
                        imported_by: this.getFullName(importData.user),
                        processed: !importData.leads_count ? '-' : importData.leads_count,
                        row_count: !importData.row_count ? '-' : importData.row_count,
                        created: !importData.leads_count ? '-' : importData.leads_count - importData.merged_leads_count,
                        merged: !importData.leads_count ? '-' : importData.merged_leads_count,
                        created_at: importData.created_at,
                        created_by: importData.created_by,
                        started_at: importData.started_at,
                        finished_at: importData.finished_at,
                        validated: importData.validated,
                        progress: !importData.started_at ? '-' : `${importData.progress}%`,
                        lead_type: LeadType.getTranslation(importData.lead_type_id) || '-',
                    };
                });
            },

            setDelimiter(value) {
                this.delimiter = value;
            },

            onValidate(row) {
                if (!row.finished_at) {
                    return;
                }

                this.validateModal.importId = row.import_id;
                this.validateModal.validated = row.validated;

                if (row.validated) {
                    this.triggerValidate();
                } else {
                    this.validateModal.opened = true;
                }
            },

            async triggerValidate() {
                await this.updateImport(this.validateModal.importId, { validated: !this.validateModal.validated });

                this.$notify.success(this.$t('imports.alerts.updatedSuccessfully'));
            },

            onValidateModalClose() {
                this.validateModal.importId = null;
                this.validateModal.validated = false;
            },

            onDelete(row) {
                this.deleteModal.opened = true;
                this.deleteModal.importId = row.import_id;
                this.deleteModal.type = row.validated ? 'danger' : 'warning';
                this.deleteModal.content = row.validated ? this.$t('imports.deleteValidatedModal') : this.$t('imports.deleteModal');
            },

            triggerDelete() {
                this.deleteImport(this.deleteModal.importId).then(
                    this.$notify.success(this.$t('imports.alerts.deletionInProgress')),
                );
            },

            submit() {
                this.creating = true;

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('original_file', this.file.name);
                formData.append('account_id', this.accountId);
                formData.append('delimiter', this.delimiter);

                this.createImport(formData).then(data => {
                    this.creating = false;

                    this.$router.push({
                        name: 'imports.match',
                        params: {
                            id: data.id,
                        },
                    });
                });
            },

            openInfoModal() {
                this.importedFieldsModalOpened = true;
            },

            fetch() {
                this.loading = true;

                this.fetchImports().then(() => {
                    this.loading = false;
                });
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.fetch();
                this.loaded = true;
            });
        },
    };
</script>
